import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { calculateSpacing } from "../../../../utils/getContainerClasses";

const GenericRichText = ({ fields, rendering, sitecoreContext }) => {
  const inverseColorClassName = (() => {
    if (!fields.inverseColor?.value) {
      return "";
    }

    let _inverseColorClass = " inverse-color";

    if (fields.inverseColor.value === "desktop-only") {
      _inverseColorClass += "-desktop";
    } else if (fields.inverseColor.value === "mobile-only") {
      _inverseColorClass += "-mobile";
    }

    return _inverseColorClass;
  })();

  return (
    <div
      className={`component comp-rte${inverseColorClassName} ${calculateSpacing(fields)} ${fields["css classes"]?.value}`}
    >
      {sitecoreContext?.route.fields["use blocks"]?.value ? (
        <div className="bg-light p-4" style={{ border: "1px solid black" }}>
          generic rte: {rendering.dataSource}
        </div>
      ) : (
        <RichText field={fields.text} />
      )}
    </div>
  );
};

export default withSitecoreContext()(GenericRichText);
